@import "~react-image-gallery/styles/css/image-gallery.css";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "../../views/Main/Main.scss";

@media (min-width: 720px) {
  .gallery {
    .image-gallery-image {
      width: 100%;
      height: 40vw;
      object-fit: cover;
    }

    span.image-gallery-description {
      color: $primary;
      font-size: xx-large;
      font-weight: 600;
      font-variant: small-caps;
      padding: .5em;
      background-color: rgba(0, 0, 0, 0.7);
      border-top-right-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
      top: 10px;
      bottom: auto;
    }

    .image-gallery-thumbnails {
      height: 10em;
      .image-gallery-thumbnails-container {
        height: 100%;
        margin: auto;
        .image-gallery-thumbnail.active,
        .image-gallery-thumbnail:hover {
          border-color: $primary;
          border-width: 3px;
          transform: scale(1.25);
          transition: all ease-in-out $transition-v-fast;
        }

        .image-gallery-thumbnail {
          margin: auto;
          margin: 0.5em;
          transition: all ease-in-out $transition-v-fast;

          span {
            height: 5em !important;
            .image-gallery-thumbnail-image {
              height: inherit;
              object-fit: cover;
            }
          }
        }
      }

      .image-gallery-svg {
        stroke: $light;
      }
    }
  }

  .watermark {
    margin-top: -18.5em;
    position: absolute;
    right: 2em;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0.5em;

    img {
      padding: 1em;
      width: 15em;
    }
  }
}

@media (max-width: $mobile) {
  .gallery {
    .image-gallery-image {
      width: 100%;
      height: 90vw;
      object-fit: cover;
    }

    span.image-gallery-description {
      color: $primary;
      font-size: larger;
      font-weight: 600;
      font-variant: small-caps;
      padding: .5em;
      background-color: rgba(0, 0, 0, 0.7);
      border-top-right-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
      top: 10px;
      bottom: auto;
    }

  }

  .watermark {
    margin-top: -3.5em;
    position: absolute;
    right: 1.5em;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0.5em;

    img {
      padding: 0.2em;
      width: 6em;
    }
  }
}

.center {
  left: 0% !important;
}
