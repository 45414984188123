@import "src/assets/scss/base.scss";

.video-intro {
  display: flex;

  iframe {
    margin: auto;
    border-radius: 1em;
  }
}

@media (max-width: $screen-qhd) {
  iframe {
    width: 1280px;
    height: 720px;
  }
}

@media (max-width: $screen-fhd){
  iframe {
    width: 1024px;
    height: 576px;
  }
}

@media (max-width: $mobile) {
  .video-intro {
    margin: 0;
    padding: 0;
    
    iframe {
      width: 640px;
      height: 360px;
      margin: 0;
      padding: 0;
    }
  }
}

