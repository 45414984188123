@import "src/assets/scss/base.scss";

.landing-start {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: fixed;
  background-position: 40% 60%;
  background-image: url("../../assets/img/banner-bg-4.jpg");
  min-height: 0 !important;

  .header {
    margin: auto;

    .subtitle-group {
      width: 700px;

      .pid {
        width: 675px;
        margin: auto;

        // PERMIUM INTERIOR DEVELOPMENT
        h2 {
          margin: auto;
          line-height: 1;
          font-weight: bolder;
          letter-spacing: -0.03em;
          border-radius: 5px;
          color: $bright;
          -webkit-text-stroke: 0;
          text-shadow: 1px 1px 10px $darker;
          padding: 0;
          font-size: 6em;
        }
      }

      // GREATER TORONTO AREA
      h3 {
        line-height: 1.25;
        font-weight: bold;
        letter-spacing: 0.07em;
        word-spacing: 9px;
        border-radius: 5px;
        color: $primary;
        -webkit-text-stroke: 0;
        font-size: 3em;
        text-align: center;
      }
    }
  }

  .container {
    display: flex;
    justify-content: end;
    margin: 0;

    .img-header {
      width: 20em;
      // margin-left: 11.3em;
    }
  }

  .container {
    margin: auto;
  }
}

@media (max-width: $mobile) {
  .landing-start {
    .container {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;

      .img-header {
        width: 17em;
        margin: auto;
        padding: 3em;
      }

      .header {
        margin: auto;
        padding-bottom: 4em;

        .subtitle-group {
          width: 100%;

          .pid {
            width: 99%;

            // PERMIUM INTERIOR DEVELOPMENT
            h2 {
              font-size: 2.5em;
              text-align: center;
            }

            .gta {
              width: 310px;
              margin: auto;
              // GREATER TORONTO AREA
              h3 {
                line-height: 1.5;
                font-size: 1.2em;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 701px) and (max-width: $screen-hd) {
  .landing-start {
    .container {
      display: flex;
      flex-direction: column;

      .img-header {
        width: 16em;
        margin: auto;
        // padding: 3em;
        padding-bottom: 3em;
      }
    }
  }

  #projects {
    padding-bottom: 8em !important;
    .projects-title {
      margin-top: 1em !important;
    }
  }
}

@media (min-width: 1281px) and (max-width: $screen-fhd) {
  .landing-start {
    background-position: 30% 60%;

    .header {
      .subtitle-group {
        width: 600px;

        .pid {
          width: 555px;

          // PERMIUM INTERIOR DEVELOPMENT
          h2 {
            font-size: 5em;
          }
        }

        // GREATER TORONTO AREA
        h3 {
          line-height: 1.25;
          letter-spacing: 1.75px;
          word-spacing: 13px;
          font-size: 40px;
        }
      }
    }

    .container {
      .img-header {
        width: 20em;
        margin: auto;
      }
    }
  }
}

@media (min-width: 1921px) {
  .landing-start {
    padding: 8em !important;
    background-position: 40% 200%;

    .container {
      .header > h2 {
        padding-top: 2em;
      }
    }
  }
}

.portfolio {
  position: relative;
  opacity: 0;
}

.portfolio[portfolio_in_view="1"] {
  position: relative;
  animation: slide-up 2.25s ease;
  opacity: 1;
}

.contact {
  position: relative;
  opacity: 0;
}

.contact[contact_in_view="1"] {
  position: relative;
  animation: slide-right 1.25s ease-out;
  opacity: 1;
}

.map-element {
  position: relative;
  opacity: 0;
}

.map-element[map_in_view="1"] {
  position: relative;
  animation: slide-left 1.75s ease;
  opacity: 1;
}

.section-photo {
  position: fixed;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
