@import "src/assets/scss/base.scss";

.values {
  height: 15em !important;

  .values-header {
    position: absolute;
    right: $min-margin-fhd;

    h3 {
      font-weight: bolder !important;
      font-size: 7em;
      letter-spacing: -0.05em !important;
      border-radius: 5px !important;
      color: $dark !important;
      line-height: 0.7;
      text-align: right;
    }
  }

  .values-description {
    p {
      height: 100%;
      margin: auto;
      font-weight: 700;
    }
  }
}

@media (max-width: $mobile) {
  .values {
    height: 100% !important;
    display: flex !important;
    flex-direction: column;

    .values-header {
      width: 100%;
      position: relative;
      right: inherit;

      h3 {
        font-size: 4em;
      }
    }

    .values-description {
      padding-top: 2em;
    }
  }
}

@media (min-width: $mobile) and (max-width: $screen-hd)  {
  .values {
    grid-template-columns: 4fr 3fr !important;
    .values-header {
      right: $min-margin;
      
      h3 {
        font-size: 4em;
      }
    }

    .values-description {
      p {
        font-size: large;
        font-weight: 600;
        padding-left: 2em;
      }
    }
  }
}

@media (min-width: $screen-hd) and (max-width: 1600px) {
  .values {
    grid-template-columns: 3fr 4fr !important;
    .values-header {
      h3 {
        font-size: 5em;
      }
    }
    .values-description {
      p {
        font-size: large;
        font-weight: 600;
      }
    }
  }
}

@media (min-width: $screen-qhd) {
  .values {
    .values-header {
      right: $min-margin-qhd;
    }
  }
}

.img-header {
  display: block;
  max-width: 40rem;
  height: auto;
  margin: auto;
}

.values-subtitle {
  background-color: $default;
  justify-content: center;
  box-shadow: 0px 1px 3px $dark;

  .values-text {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: bold;
    font-size: large;
    color: $secondary;
    text-transform: uppercase !important;
    max-width: fit-content;
  }
}

.img-values {
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  object-fit: cover;
  z-index: -1;
}

.logo-container {
  min-height: 100%;
}
