@import "src/assets/scss/base.scss";
.industry {
  margin: auto;
  height: 100%;

  .industry-header {
    position: absolute;
    left: $min-margin-fhd;

    h3 {
      position: relative;
      font-weight: bolder !important;
      font-size: 7em;
      letter-spacing: -0.05em !important;
      line-height: 0.7;
      text-align: left;
      color: $dark;
    }

    #commercial {
      animation: slide-right 1s ease-out;
    }

    #custom-homes {
      animation: slide-right 1.5s ease-out;
    }

    #developments {
      animation: slide-right 1.25s ease-out;
    }
  }

  .industry-description {
    p {
      font-weight: 700;
    }
  }
}

@media (max-width: $mobile) {
  .industry {
    display: flex !important;
    flex-direction: column;

    .industry-header {
      width: 100%;
      position: relative;
      left: inherit;

      h3 {
        font-size: 2.6em;
      }
    }

    .industry-description {
      padding-top: 2em;
    }
  }
}

@media (min-width: $mobile) and (max-width: $screen-hd) {
  .industry {
    grid-template-columns: 1fr 1fr !important;
    .industry-header {
      left: $min-margin;
      
      h3 {
        font-size: 4em;
      }
    }

    .industry-description {
      p {
        font-size: large;
        font-weight: 600;
        padding-left: 2em;
      }
    }
  }
}

@media (min-width: $screen-hd) and (max-width: 1600px) {
  .industry {
    .industry-header {
      h3 {
        font-size: 5em;
      }
    }

    .industry-description {
      p {
        font-size: large;
        font-weight: 600;
      }
    }
  }
}

@media (min-width: $screen-fhd) {
  .industries-section {
    height: 20em;
  }
}

@media (min-width: $screen-qhd) {
  .industry {
    .industry-header {
      left: $min-margin-qhd;
    }
  }
}
