@import "src/assets/scss/base.scss";

.clients-section {
  height: 23em;
}

.client-container {
  height: 100%;
}

.client-grid {
  grid-template-columns: 4fr 9fr;
}

.client-header {
  position: absolute;
  left: $min-margin-fhd;

  p {
    font-weight: bolder !important;
    font-size: 7em;
    letter-spacing: -0.05em !important;
    border-radius: 5px !important;
    color: $primary;
    line-height: 0.7;
  }
}

.client-icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height: 100%;

  .client-logo {
    margin: auto;
    max-width: 100%;
    padding: 0.5em;
  }
}

@media (max-width: $mobile) {  
  .clients-section {
    height: auto;
  }

  .client-container {
    padding: 2em;
  }

  .client-grid {
    grid-template-columns: 1fr;
    .client-header {
      width: 100%;
      position: relative;
      left: inherit;
      padding-bottom: 3em;

      p {
        text-align: center;
        font-size: 4em;
      }
    }

    .client-icons {
      grid-template-columns: 1fr 1fr;
      padding-top: 2em;
    }
  }
}

@media (min-width: $mobile) and (max-width: $screen-hd) {
  .client-grid {
    .client-header {
      left: $min-margin;

      p {
        font-size: 4em;
      }
    }
  }
}

@media (min-width: $screen-hd) and (max-width: 1600px) {
  .client-grid {
    .client-header {
      p {
        font-size: 5em;
      }
    }
  }
}

@media (min-width: $screen-qhd) {
  .client-grid {
    .client-header {
      left: $min-margin-qhd;
    }
  }
}
