// @import "src/assets/scss/argon-design-system-react.scss";
// @import "src/assets/scss/argon-design-system/variables";
@import "src/assets/scss/base.scss";

.whatsapp-button {
  height: 3.5rem !important;
  width: 100%;
  border-radius: 0.5rem;
  background-color: #d3e9d5 !important;
  border: 2px solid #95dd9d !important;
}

.whatsapp-img {
  object-fit: scale-down;
  height: 90%;
  width: auto;
}

.card {
  .card-blockquote {
    padding: 2rem;
    position: relative;

    .svg-bg {
      display: block;
      width: 100%;
      height: 95px;
      position: absolute;
      top: -94px;
      left: 0;
    }
  }

  .img-card {
    object-fit: cover;
    width: 100%;
    height: 15rem;
  }

  .card-img-col {
    margin: -1.25rem;
    width: 15rem;
    height: 15rem;
  }

  .title {
    font-weight: bold;
    padding-bottom: 0.75rem;
  }

  .subtitle {
    margin-bottom: 0.5rem;
    color: black;
  }

  .description {
    margin-bottom: 0;
    color: $default;
    text-align: justify;
  }
}

@media (min-width: $screen-fhd) {
  .contact-form {
    margin-right: 10em !important;
    margin-left: 5em !important;
  }

  .map-element {
    margin-right: 5em !important;
    margin-left: 10em !important;
  }
}

.section-contact-us {
  background-color: $off-white;
}
