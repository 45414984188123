// @import "src/assets/scss/argon-design-system-react.scss";
// @import "src/assets/scss/argon-design-system/variables";
@import "src/assets/scss/base.scss";

.services-title {
  width: 100%;
  text-align: center;
}

// .services-section {
//   padding-bottom: 10em;
// }

.service-item[services_in_view="0"] {
  opacity: 0;
}

#metal-frame[services_in_view="1"] {
  position: relative;
  animation: slide-up 1s ease-in-out;
}

#spray-foam[services_in_view="1"] {
  position: relative;
  animation: slide-up 1.25s ease-in-out;
}
#insulation[services_in_view="1"] {
  position: relative;
  animation: slide-up 1.5s ease-in-out;
}
#drywall[services_in_view="1"] {
  position: relative;
  animation: slide-up 1.75s ease-in-out;
}
#taping[services_in_view="1"] {
  position: relative;
  animation: slide-up 2s ease-in-out;
}

.separator {
  margin: auto;
  max-height: 1px;
  max-width: 80%;
  background-color: $gray;
}

.service-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-around;

  .card {
    height: 15em;
    width: 15em;
    margin-right: 1em;
    margin-left: 1em;
    border-radius: 0.5em;

    .card-body {
      height: 100%;
      img {
        height: 10em;
        border-radius: 5px;
        object-fit: fill;
      }

      h5 {
        font-weight: bold;
        text-align: center;
        margin-bottom: 0;
        padding-top: 1em;
        padding-bottom: 0;
        color: $primary;
      }
    }
  }

  .card:hover {
    height: 40em;
    width: 25em;

    img {
      object-fit: cover;
      height: 15em;
    }

    p {
      margin-top: 1em;
      text-align: justify;
      text-justify: inter-word;
    }
  }
}

.service-list-items {
  justify-content: center;

  .service-list-item {
    margin-right: 0.5rem;
    margin-left: 0.5rem;

    .service-img {
      object-fit: cover;
      width: 100%;
      height: 9rem;
      border-radius: 0.5rem;
    }
  }

  .service-item-title {
    margin-bottom: 0.75rem;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid $primary;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}

.corner-poly {
  display: flex;
  height: 100%;
  align-items: end;
}

.services-heading {
  p {
    text-align: center;
  }

  .services-img-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .outer-frame {
      margin: 1em;
      div {
        width: 28em;
        height: 25em;

        .corner-poly {
          svg {
            height: 50px;
            width: 50px;
            polygon {
              fill: $primary;
              opacity: 80%;
            }
          }
        }

        .service-description {
          opacity: 0;
          height: inherit;
          position: absolute;
          font-size: large;
          color: $text;
          font-weight: 500;
          margin: 1.4em;
          margin-top: 4em;
          line-height: 1.25;
          text-align: left;
          text-shadow: 1px 1px 10px $darker;
          transition: opacity $transition-fast;
          z-index: 1;
        }

        h3 {
          height: inherit;
          width: inherit;
          position: absolute;
          font-size: xx-large;
          color: $off-white;
          font-weight: bold;
          margin: 0.75em;
          transition: all $transition-fast;
          z-index: 1;
        }

        .dark-fill {
          position: absolute;
          background-color: $darker;
          transition: opacity $transition-fast;
          opacity: 0;
          z-index: 0;
        }
      }
    }

    .service-item:hover {
      h3 {
        transition: all $transition-v-fast;
        color: $primary;
        text-shadow: 1px 1px 0.1em $dark;
      }

      .service-description {
        opacity: 1;
        transition: opacity $transition-med;
      }

      .dark-fill {
        transition: opacity $transition-med !important;
        opacity: 75% !important;
      }
    }

    .bg-frame {
      background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
        center / cover no-repeat url("../../../assets/img/metal-frame.jpeg");
    }

    .bg-spray {
      background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
        center / cover no-repeat url("../../../assets/img/sprayfoam.jpeg");
    }
    .bg-insulation {
      background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
        center / cover no-repeat url("../../../assets/img/insulation.jpeg");
    }

    .bg-drywall {
      background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
        center / cover no-repeat url("../../../assets/img/drywall-2.jpg");
    }
    .bg-taping {
      background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
        center / cover no-repeat url("../../../assets/img/taping-ceiling.jpeg");
    }
  }

  .services-img-section.cols-11 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: $mobile) {
  .services-title {
    width: 100%;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .services-section {
    .services-heading {
      .services-img-section {
        display: flex;
        flex-direction: column;
        .outer-frame {
          margin: auto auto 1em auto;
          width: 100%;
          div {
            width: 100%;
            height: 100vw;

            .service-description {
              font-size: medium;
              margin: 3em 1.25em 0 1.25em;
              line-height: 1;
            }
    
            h3 {
              font-size: larger;
              margin-left: 1em;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $mobile) and (max-width: $screen-hd) {
  .services-section {
    .services-heading {
      .services-img-section {
        .outer-frame {
          div {
            width: 25em;
            height: 30em;
          }
        }
      }
    }
  }
}
