@import "src/assets/scss/variables.scss";

.nav-brand {
  overflow: hidden;

  .nav-logo {
    height: $navbar-height !important;
    object-fit: contain;
  }
}

.nav-link {
  color: $primary !important;
}

.navbar-nav {
  a > span {
    font-size: larger;
    transition: color $transition-fast;
    color: $text;
    margin: 0 !important;
  }

  li:hover {
    transition: border $transition-v-fast ease-out;
    border-bottom: 10px solid $primary;

    a > span {
      transition: color $transition-fast;
      color: $primary;
    }
  }

  li {
    transition: border $transition-v-fast ease-out;
    border-bottom: 0px solid $primary;
    padding: 0 0.25em 0 0.25em;

    a > span {
      transition: color $transition-v-fast;
      color: $text;
    }
  }
}

@media (max-width: 570px) {
  nav {
    height: 4em;
    .container {
      position: absolute;
      right: 1em;
      margin: 0 !important;
      padding: 0 !important;

      .collapse, .collapsing-out, .collapsing {
        margin-left: auto;
        width: 12em;

        .navbar-collapse-header {
          display: flex;
          justify-content: end;
        }

      .nav-brand {
        visibility: hidden;
        width: 0;
        height: 0;
      }

      .navbar-toggler {
        margin-top: 0;
      }

      li:hover {
        a > span {
          width: 100%;
          text-align: right;
          color: $default;
        }
      }

      li {
        a {
          text-align: right !important;
          
          span {
            width: 100%;
            color: $default;
          }
        }
      }
    }
    }
  }

  .nav-brand {
    visibility: hidden;
  }
}

@media (min-width: 570px) and (max-width: $screen-hd) {
  nav {
    height: 5em;

    .nav-brand {
      width: 0;
      height: 0;
      visibility: hidden;
    }

    li:hover {
      a > span {
        color: $text !important;
      }
    }

    li {
      a > span {
        font-size: medium;
        color: $text !important;
      }
    }
  }

  .navbar-nav {
    margin: auto;
  }
}

@media (min-width: $screen-hd) and (max-width: 1600px) {
  .navbar-nav {
    height: $navbar-height-hd;

    a > span {
      font-size: medium;
    }
  }

  .nav-brand {
    .nav-logo {
      height: $navbar-height-hd !important;
    }
  }
}
